const RENEWAL_REMINDER_EN = {
  renewalReminder: 'Renewal Reminder',
  id: 'Id',
  customerId: 'Customer Id',
  subscriptionId: 'Subscription Id',
  sent: 'Sent',
  sentWithTime: 'Sent With Time',
  created: 'Created',
  createdWithTime: 'Created With Time',
  updated: 'Updated',
  updatedWithTime: 'Updated With Time',
};

export default RENEWAL_REMINDER_EN;
