import {
  DataGridPro as DataGrid,
  getGridStringOperators,
  GridColumns,
  GridFilterModel,
  gridPageCountSelector,
  gridPageSelector,
  GridRowId,
  GridRowModel,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Paginated } from '../../Paginated';
import { fold, isSuccess, RemoteData } from '../../../utils/remote-data';
import { useAccessToken } from '../../../authentication';
import { isAdminUser } from '../../../authentication/auth';
import { Theme } from '@emotion/react';
import { Button, ButtonProps, Pagination, SxProps, TextField } from '@mui/material';
import { filterModelToBackofficeQueries, PageProps, SearchParams } from './filters/backoffice-query';
import { Icons } from '..';
import { useTranslation } from 'react-i18next';

const filterOperators = () => getGridStringOperators().filter((operator) => operator.value === 'equals');

export type Sort<A> = SortParam<A> | null;

export interface SortParam<A> {
  orderBy: keyof A & string;
  order: 'asc' | 'desc' | null | undefined;
}

export interface RowsState<A> {
  page: number;
  pageSize: number;
  rows: GridRowModel[];
  loading: boolean;
  total: number;
  searchParams: SearchParams;
  sort: Sort<A>;
}

interface Props<A> {
  cols: GridColumns;
  fetcher: (pageProps: PageProps) => Promise<RemoteData<Paginated<A>, unknown>>;
  datas: RemoteData<Paginated<A>, unknown>;
  checkboxSelection?: boolean;
  toRow: (d: A) => GridRowModel;
  toCsvRow?: (d: A) => GridRowModel;
  getRowId?: (d: A) => GridRowId;
  actions?: (params: GridRowParams) => JSX.Element;
  forceReload?: boolean;
  hasToolbar?: boolean;
  sx?: SxProps<Theme> | undefined;
  renameFilterFields?: {
    original: string;
    renameto: string;
    parse: (from: string) => unknown;
  };
  hideFooter?: boolean;
  id: string;
  defaultFilters?: GridFilterModel;
  defaultSort?: Sort<A>;
  maxExportPageSize?: number;
  initialColumnVisibilityModel?: Partial<{ [K in keyof A]: boolean }> & { [key: string]: boolean };
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <div style={{ alignItems: 'center', display: 'flex', padding: '0 5px' }}>
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        showFirstButton
        showLastButton
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      ></Pagination>
      <TextField
        size="small"
        label="Page"
        variant="outlined"
        value={`${page + 1}`}
        onChange={(event) => apiRef.current.setPage(+event.target.value - 1)}
      />
    </div>
  );
}

function DataTable<A>(props: Props<A>) {
  const {
    cols,
    checkboxSelection,
    fetcher,
    datas,
    toRow,
    forceReload,
    id,
    hasToolbar: hasAction = true,
    hideFooter = false,
    sx,
    defaultFilters,
    maxExportPageSize = 500,
    toCsvRow,
    initialColumnVisibilityModel,
  } = props;

  const [page_, setPage] = useState(0);
  const [pageSize_, setPageSize] = useState(20);
  const [rows_, setRows] = useState<{ [key: string]: unknown }[]>([]);
  const [loading_, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [searchParams_, setSearchParams] = useState(
    defaultFilters ? { queries: filterModelToBackofficeQueries(defaultFilters) } : ({} as { queries: SearchParams }),
  );
  const [sort_, setSort] = useState<Sort<A>>(props.defaultSort || null);
  const [user] = useAccessToken();
  const apiRef = useGridApiRef();

  const memoFetcher = useCallback(
    (pageNumber: number, pageSize: number, searchParams?: { queries: SearchParams }, sort?: Sort<A>) => {
      fetcher({ pageNumber, pageSize, ...searchParams, ...sort });
    },
    [fetcher],
  );

  useEffect(() => {
    memoFetcher(page_ + 1, pageSize_, searchParams_, sort_);
  }, [memoFetcher, page_, pageSize_, searchParams_, sort_]);

  useEffect(() => {
    if (forceReload) memoFetcher(page_ + 1, pageSize_, searchParams_, sort_);
    // do not remove force reload
  }, [memoFetcher, forceReload, page_, pageSize_, searchParams_, sort_]);

  useEffect(() => {
    return fold(
      () => {
        setLoading(true);
      },
      () => {
        setLoading(true);
      },
      (data: Paginated<A>) => {
        setLoading(false);
        setRows(data.data.map(toRow));
        setPage(+data.pagination.currentPage - 1);
        setTotal(data.pagination.total);
      },
      () => {
        setLoading(false);
      },
    )(datas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas]);

  const onPageChange = useCallback(
    (page: number) => {
      if (page_ === page) return;
      setPage(page);
    },
    [page_],
  );

  const onPageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
  }, []);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    const queries = filterModelToBackofficeQueries(filterModel);

    setSearchParams({ queries });
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const sorts = sortModel.map((sortM) => ({
      orderBy: sortM.field as keyof A & string,
      order: sortM.sort,
    }));
    setSort(sorts[0]);
  }, []);

  const state = useMemo(() => {
    const s = JSON.parse(
      window.localStorage.getItem(`${id}_state`) ||
        `{ "columns": { "columnVisibilityModel": ${JSON.stringify(initialColumnVisibilityModel || {})} } }`,
    );
    return {
      columns: s.columns,
      filter: {
        filterModel: defaultFilters,
      },
    };
  }, [id, initialColumnVisibilityModel, defaultFilters]);

  const columns = useMemo(
    (): GridColumns =>
      cols
        .map((col) => ({
          ...col,
          align: 'center',
          headerAlign: 'center',
          filterOperators: col.filterOperators || filterOperators(),
        }))
        .sort((a, b) => {
          if (state.columns?.orderedFields?.length) {
            const aValue = state.columns.orderedFields.indexOf(a.field);
            const bValue = state.columns.orderedFields.indexOf(b.field);
            return aValue - bValue;
          }

          return 0;
        }) as GridColumns,
    [cols, state?.columns?.orderedFields],
  );

  const CustomToolbar = () => {
    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <Icons.Export />,
    };

    const { t } = useTranslation('common');
    const handleExport = async () => {
      // Save the row present before file generation
      const currentRowIds = apiRef.current.getAllRowIds();

      // update data to set all the rows
      const allRows = await fetcher({ pageNumber: 1, pageSize: maxExportPageSize, ...searchParams_, ...sort_ });
      if (!isSuccess(allRows)) return;

      const mappedRows = allRows.value.data.map(toRow);

      const csvRows = toCsvRow ? allRows.value.data.map(toCsvRow) : mappedRows;

      apiRef.current.updateRows(csvRows);

      apiRef.current.exportDataAsCsv(); // notice that file generation is an async function

      apiRef.current.updateRows(mappedRows);

      // Delete rows added for the file generation
      const idsToDelete = allRows.value.data.map((row) => toRow(row).id).filter((id_) => !currentRowIds.includes(id_));
      apiRef.current.updateRows(idsToDelete.map((rowId) => ({ id: rowId, _action: 'delete' })));
    };

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          touchRippleRef={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        />

        <GridToolbarFilterButton
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />

        <GridToolbarDensitySelector
          touchRippleRef={undefined}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
        {isAdminUser(user) && (
          <Button {...buttonBaseProps} onClick={() => handleExport()}>
            {t('export')}
          </Button>
        )}
      </GridToolbarContainer>
    );
  };

  return (
    <DataGrid
      sx={sx}
      columns={columns}
      rowCount={total}
      rowsPerPageOptions={[pageSize_]}
      checkboxSelection={checkboxSelection}
      paginationMode="server"
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      components={{ Toolbar: hasAction ? CustomToolbar : undefined, Pagination: CustomPagination }}
      hideFooter={hideFooter}
      pagination
      autoHeight
      apiRef={apiRef}
      page={page_}
      pageSize={pageSize_}
      rows={rows_}
      loading={loading_}
      filterMode="server"
      onFilterModelChange={onFilterChange}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      getRowId={props.getRowId ? props.getRowId : (row) => row.id}
      onColumnVisibilityModelChange={() => {
        window.localStorage.setItem(`${id}_state`, JSON.stringify(apiRef.current.exportState()));
      }}
      onColumnOrderChange={() => {
        window.localStorage.setItem(`${id}_state`, JSON.stringify(apiRef.current.exportState()));
      }}
      onColumnWidthChange={() => {
        window.localStorage.setItem(`${id}_state`, JSON.stringify(apiRef.current.exportState()));
      }}
      initialState={state}
    />
  );
}

export default DataTable;
